
import {
    defineComponent, reactive, computed, onMounted,
} from 'vue';
import TrackedItem from '@/domain/TrackedItem';
import Screen from '@/components/layout/Screen.vue';
import TagInput from '@/components/inputs/TagInput.vue';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';
import { useNotification } from '@/composable/useNotifications';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    trackedItem: TrackedItem;
    searching: boolean;
    saving: boolean;
    oldTag: string;
    newTag: string;
}

export default defineComponent({
    name: 'inventory-replace-tag',
    components: {
        Thumbnail,
        Screen,
        TagInput,
        SmartTrakFooter,
    },
    props: {},
    setup() {
        const trackedItemService = new TrackedItemService();
        const notifications = useNotification();

        const state = reactive<State>({
            trackedItem: new TrackedItem(),
            searching: false,
            saving: false,
            oldTag: '',
            newTag: '',
        });

        const saveable = computed(() => !state.saving && state.trackedItem.id && state.newTag);

        function resetForm(): void {
            state.trackedItem = new TrackedItem();
            state.searching = false;
            state.oldTag = '';
            state.newTag = '';
        }

        async function findItem(barcode: string) {
            state.searching = true;
            const trackedItem = await trackedItemService.getTrackedItemByBarcode(barcode);
            if (trackedItem?.deleted) {
                notifications.showError(getTranslation('core.validation.trackedItemWithTagWasScrapped', barcode));
                resetForm();
            } else if (trackedItem) {
                state.trackedItem = trackedItem;
            } else {
                notifications.showError(getTranslation('core.validation.tagDoesNotExistInSystem', barcode));
                resetForm();
            }
            state.searching = false;
        }

        async function updateItem(barcode: string) {
            state.trackedItem.barcode = barcode;
            await trackedItemService.updateTrackedItem(state.trackedItem);
            resetForm();
        }

        onMounted(async () => {
            resetForm();
        });

        return {
            state,
            findItem,
            updateItem,
            resetForm,
            saveable,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});

