import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-section bordered" }
const _hoisted_2 = { class: "form-section bordered" }
const _hoisted_3 = { class: "centered" }
const _hoisted_4 = { class: "centered" }
const _hoisted_5 = { class: "form-section bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_input = _resolveComponent("tag-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.getTitleCaseTranslation('core.domain.replaceTag'),
    heading: _ctx.getTitleCaseTranslation('core.domain.replaceTag'),
    full: "",
    padded: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            disabled: !_ctx.saveable,
            variant: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateItem(_ctx.state.newTag)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.replace')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_tag_input, {
                  modelValue: _ctx.state.oldTag,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.oldTag) = $event)),
                  disabled: _ctx.state.trackedItem.itemId > 0,
                  autofocus: !(_ctx.state.trackedItem.itemId > 0),
                  searchable: "",
                  label: _ctx.getTitleCaseTranslation('core.domain.oldTag'),
                  placeholder: _ctx.getTranslation('core.common.scanOrEnterNewTag'),
                  onScan: _ctx.findItem,
                  onClear: _ctx.resetForm
                }, null, 8, ["modelValue", "disabled", "autofocus", "label", "placeholder", "onScan", "onClear"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_b_form_input, {
                  label: _ctx.getTitleCaseTranslation('core.domain.item'),
                  readonly: true,
                  "model-value": _ctx.state.trackedItem.item?.name
                }, null, 8, ["label", "model-value"]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_thumbnail, {
                    "image-url-thumb": _ctx.state.trackedItem.item?.imageUrlThumb,
                    "image-url-full": _ctx.state.trackedItem.item?.imageUrlFull,
                    size: "lg"
                  }, null, 8, ["image-url-thumb", "image-url-full"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_faicon, {
                    icon: "arrow-right",
                    size: "3x"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_tag_input, {
                  modelValue: _ctx.state.newTag,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.newTag) = $event)),
                  disabled: !(_ctx.state.trackedItem.itemId > 0),
                  autofocus: _ctx.state.trackedItem.itemId > 0,
                  label: _ctx.getTitleCaseTranslation('core.domain.newTag'),
                  onScan: _ctx.updateItem,
                  onClear: _ctx.resetForm
                }, null, 8, ["modelValue", "disabled", "autofocus", "label", "onScan", "onClear"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "heading"]))
}